// PAGE PROVISOIRE

.provisoire {
    position: relative;
    background: transparent url(../images/provisoire.jpg) center no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 100vh;
    width: 100%;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: block;
        background-color: fadeout(@color, 20%);
        z-index: 20;
    }

    > article {
        position: absolute;
        width: 350px;
        top: 25%;
        left: 50%;
        margin-left: -175px;
        color: #fff;
        text-align: center;
        z-index: 30;

        p {
            font-size: 2rem;
        }
    }

    .logo {
        margin: auto;
        margin-bottom: 25px;
        width: 250px;
        height: 250px;
        text-indent: -9999px;
    }
} // .provisoire

