/////// GENERAL /////

html {
    font-size: 62.5%;
}

body {
    background-color: #fff;
    font-size: 1.4rem;
    line-height: 1.4;
    font-family: @font;
    color: @fontcolor;
    min-height: 100vh;
    .flex(column,stretch,space-between);

    .content {
        flex-grow: 1;
    }
}

// TOOLS

.clear {
    clear: both;
}

.hide {
    display: none;
}

.hidden {
    visibility: hidden;
}

a {
    color: @color;
    text-decoration: none;
    .transition;
}

strong,
b {
    font-weight: 700;
}

abbr {
    border-bottom: 1px dotted #555;
}

sup {
    vertical-align: super;
    font-size: 0.8em;
}

.blur {
    opacity: 0.4;
}

.wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    position: relative;
}

.logo {
    background-image: url(../images/logo.svg);
    background-size: contain;

    &.invert {
        background-image: url(../images/logo-invert.svg);
    }
}

/// LAYOUT ///

.cols {
    display: flex;
    justify-content: space-between;
}

// HEADINGS

h1,
.h1 {
    // ...
}

h2,
.h2 {
    // ...
}
