// BLOCS & COMPONENTS

// BOUTONS ///

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0 15px;
    border: 1px solid transparent;
    font-size: 1.4rem;
    font-weight: 700;
    min-height: 45px;
    height: 45px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.18rem;
    .rounded(3px);

    &.btn-primary {
        background-color: @color;
        color: #fff;

        &:hover {
            background-color: transparent;
            border-color: @color;
            color: @color;
        }
    }

    &.btn-invert {
        background-color: transparent;
        border-color: @color;
        color: @color;

        &:hover {
            background-color: @color;
            color: #fff;
        }
    }
} // btn


.back {
    display: flex;
    align-items: center;
    margin-right: 40px;
    font-size: 1.2rem;

    @media @mobile {
        margin-bottom: 20px;
        margin-top: 5px;
        margin-right: 0;
    }

    i {
        color: @color2;
        margin-right: 5px;
    }

    span {
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.3rem;
        color: @color;
    }

    &:hover {
        span {
            color: @color2;
        }
    }
}

///// BREACRUMB /////

.breadcrumb {
    color: #a7a7a7;
    font-size: 1rem;
    text-transform: uppercase;

    @media @mobile {
        margin-bottom: 15px;
        margin-top: 5px;
    }

    span {
        text-decoration: underline;
    }

    a {
        color: #a7a7a7;

        &:hover {
            color: @color2;
        }
    }
} // .breadcrumb

//// POPUP /////

body {
    &.mode-popup {
        &::after {
            display: block;
            content: '';
            position: fixed;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.7);
            z-index: 9998;
        }
    }
}

.popup {
    display: none;

    &.open {
        display: block;
        background-color: #fff;
        color: #333;
        text-align: right;
        position: fixed;
        top: 10%;
        left: 50%;
        height: auto;
        width: 90%;
        max-width: 790px;
        margin-left: -395px;
        z-index: 9999;
        padding: 15px;

        @media screen and (max-width: 880px) { /* 640 */
            left: 5%;
            right: 5%;
            width: auto;
            max-width: 90%;
            margin-left: 0;
        }

        @media @mobile { /* 640 */
            left: 0;
            right: 0;
            max-width: 100%;
            top: 0;
            bottom: 0;
            overflow-y: scroll;
        }

        h3 {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.8rem;
            margin-bottom: 20px;
            text-align: left;
        }

        .cols {
            display: flex;
            justify-content: space-between;

            > div {
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }

    .close-popup {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #333;

        i {
            font-size: 2.8rem;
        }

        &:hover {
            color: @color2;
        }

        @media @mobile {
            position: fixed;
        }
    }
} // popup

// ONGLETS

.nav-onglets {
    display: flex;

    a {
        margin: 0 20px;

        &.actif {
            text-decoration: underline;
        }
    }
} // .nav-onglets

.onglet {
    display: none;

    &.open {
        display: block;
    }
} // .onglet


//// PHP PAGINATION //////
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    a,
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #eee;
        margin-right: 10px;
        color: #333;
        height: 30px;
        width: 30px;
        .rounded(50%);

        &:only-child {
            display: none;
        }

        &:hover,
        &.current {
            background-color: @color;
            color: #fff;
        }

        &.dots {
            background-color: transparent;
            font-size: 2rem;
        }
    }
} // pw-pagination
